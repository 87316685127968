import React, { useState } from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import styled from "styled-components"
import classNames from "classnames"

import Logo from "../images/logo-header.svg"
import useSiteMetadata from "../hooks/use-site-metadata"

const StyledWrapper = styled.nav`
  padding-right: 10px;
  padding-left: 10px;
  .navbar-brand .navbar-item {
    padding-top: 10px;
    padding-bottom: 10px;
    img {
      max-height: unset;
      max-width: 60vw;
    }
  }
`
export default function Header() {
  const [active, setIsActive] = useState(false)
  const site = useSiteMetadata()
  return (
    <StyledWrapper className="navbar" role="navigation" aria-label="main navigation">
      <div className="navbar-brand">
        <Link to="/" className="navbar-item">
          <img src={Logo} alt={site.siteMetadata.title} width="300" height="55"/>
        </Link>
        <button
          type="button"
          className={classNames("navbar-burger burger is-link", active && "is-active")}
          aria-label="menu"
          aria-expanded="false"
          data-target="head-navbar"
          onClick={() => setIsActive(!active)}
        >
          <span aria-hidden="true"/>
          <span aria-hidden="true"/>
          <span aria-hidden="true"/>
        </button>
      </div>
      <div
        id="head-navbar"
        className={classNames("navbar-menu", active && "is-active")}
      >
        <div className="navbar-end">
          <Link
            to="/about"
            className="navbar-item"
            activeClassName="is-active"
            partiallyActive
          >
            About
          </Link>
          <Link
            to="/authors"
            className="navbar-item"
            activeClassName="is-active"
            partiallyActive
          >
            Authors
          </Link>
          <Link
            to="/unscripted"
            className="navbar-item"
            activeClassName="is-active"
            partiallyActive
          >
            Unscripted
          </Link>
          <Link
            to="/news"
            className="navbar-item"
            activeClassName="is-active"
            partiallyActive
          >
            News
          </Link>
          <Link
            to="/contact-us"
            className="navbar-item"
            activeClassName="is-active"
            partiallyActive
          >
            Contact us
          </Link>
          <a
            className="navbar-item"
            href="https://www.linkedin.com/company/sphere-contents"
            target="_blank"
            rel="noreferrer"
            aria-label="Linkedin"
          >
            <i className="fab fa-linkedin"/>
          </a>
        </div>
      </div>
    </StyledWrapper>
  )
}
Header.propTypes = {
  data: PropTypes.shape({})
}
